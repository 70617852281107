import type { LocalizedTextInput, Slug } from '@mybonus/public';
import { useAPI, useMutation, useQuery, APIInput, useForm } from '@mybonus/ui';
import { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export function useCmsPageUpsertView() {
	const params = useParams();
	const { api } = useAPI();

	const create = useMutation<APIInput['admin']['cms']['page']['create']>((input) =>
		api.admin.cms.page.create.mutate(input),
	);

	const singleInput = useMemo(() => ({ id: Number(params.id) }), [params.id]);

	const single = useQuery(
		'admin.cms.page.single',
		(input) => api.admin.cms.page.single.query(input),
		singleInput,
		{ preventAutoload: !params.id, ttl: false },
	);

	const mode = single.data ? 'Update' : 'Create';

	const update = useMutation<APIInput['admin']['cms']['page']['update']>((input) =>
		api.admin.cms.page.update.mutate(input),
	);

	const form = useForm<{
		slug: Slug;
		tagId: number;
		heroId: number | null;
		content: LocalizedTextInput;
		active: boolean;
	}>({
		onSubmit: () => {
			console.log('onSubmit', form.data);

			if (single.data?.id) {
				update.mutate({ id: single.data.id, data: form.data });
			} else {
				create.mutate(form.data);
				form.reset();
			}
		},
		validations: {
			slug: {
				required: {
					message: 'A slug is required',
				},
			},
			tagId: {
				required: {
					message: 'A tag is required',
				},
			},
			content: {
				required: {
					message: 'Content is required',
				},
			},
		},
		initialValues: {
			slug: '',
			active: true,
			content: {
				sv: { text: '' },
			},
		},
	});

	function handleReset() {
		create.reset();
		update.reset();
	}

	const isSuccess = !!create.isSuccess || !!update.isSuccess;
	const isLoading = single.isLoading || create.isLoading || update.isLoading;
	const error = single.error || create.error || update.error;
	const notFound = params.id && !isLoading && !single.data;

	useEffect(() => {
		if (single.data) {
			form.setData({
				...form.data,
				...single.data,
			});
		}
	}, [single.data]);

	return {
		isSuccess,
		isLoading,
		error,
		notFound,
		params,
		single,
		mode,
		form,
		handleReset,
	};
}
