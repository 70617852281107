import { httpBatchLink } from '@trpc/client';
import base64 from 'base-64';
import superjson from 'superjson';

import { NetworkError, UnauthenticatedError, Errors } from '../err';
import { PlatformAlias, JWTData, Account } from '../types';
import { AccessGrant, AccountType } from '../types';
import { isBrowser } from '../utils';
import { rethrowRawServerError } from './error';
import type { AuthStorage } from './types';

const TOKEN_REFRESH_LEEWAY_SEC = 60 * 2;

export class TRPCClient {
	protected refreshTokenProcess: Promise<void> | null = null;
	protected _unRecoverableAuthErrorListeners: (() => void)[] = [];

	constructor(
		protected args: {
			url: string;
			platform: {
				alias: PlatformAlias;
				version: string;
			};
			accountType: AccountType;
			authStorage: AuthStorage;
		},
	) {}

	createConfig(): any {
		return {
			transformer: superjson,
			links: [
				httpBatchLink({
					url: this.args.url,
					fetch: this.fetch.bind(this),
					maxURLLength: 2083, // a suitable size
				}),
			],
		};
	}

	onUnrecoverableAuthError(handler: () => void) {
		this._unRecoverableAuthErrorListeners.push(handler);
	}

	protected emitUnrecoverableAuthError(): void {
		this._unRecoverableAuthErrorListeners.forEach((handler) => handler());
	}

	protected async fetch(
		input: RequestInfo | any,
		init?: RequestInit,
		myOpts?: { withoutAuth?: boolean },
	): Promise<Response> {
		if (!myOpts?.withoutAuth) {
			await this.startTokenRefresh();
		}

		const accessGrant = await this.getAccessGrant();

		const headers = {
			...init?.headers,
			'X-Platform-Alias': this.args.platform.alias,
			'X-Platform-Version': this.args.platform.version,
			...(accessGrant ? { 'X-Device-Id': accessGrant.deviceId } : {}),
			...(accessGrant && !myOpts?.withoutAuth ? { Authorization: accessGrant.accessToken } : {}),
		};

		let res;

		try {
			res = await fetch(input, { ...init, headers });
		} catch (_err) {
			const err = _err as Error;
			throw new NetworkError(`Could not connect to API: ${err.name}: ${err.message}`);
		}

		return res;
	}

	async getAccessGrant(): Promise<AccessGrant | null> {
		const value = await this.args.authStorage.getData();

		if (!value) {
			return null;
		}

		return JSON.parse(value);
	}

	protected async startTokenRefresh(): Promise<void> {
		if (!this.refreshTokenProcess) {
			this.refreshTokenProcess = this.ensureTokenRefresh();
		}

		try {
			await this.refreshTokenProcess;
		} finally {
			this.refreshTokenProcess = null;
		}
	}

	protected async ensureTokenRefresh(): Promise<void> {
		let accessGrant = await this.getAccessGrant();

		// TODO: Remove this after webb 2.0 has been out there a while
		if (!accessGrant) {
			accessGrant = await this.tryGetAccessGrantByLegacyCookie();
		}

		if (!accessGrant) {
			return; // Cannot refresh since we don't have any access grant previously stored
		}

		const shouldRefresh = this.isJWTExpired(accessGrant.accessToken);

		if (!shouldRefresh) {
			return;
		}

		const canRefresh = !this.isJWTExpired(accessGrant.refreshToken);

		if (!canRefresh) {
			await this.args.authStorage.clearData();
			this.emitUnrecoverableAuthError();
			throw new UnauthenticatedError('Refresh token is expired, cannot refresh access token');
		}

		await this.refreshAccessToken(accessGrant);
	}

	// TODO: Remove this some time after deployment of web 2.0
	async tryGetAccessGrantByLegacyCookie(): Promise<AccessGrant | null> {
		if (!isBrowser()) {
			return null;
		}

		if (!document.cookie) {
			console.debug('No cookies to use');
			return null;
		}

		if (window.localStorage.getItem('has_converted_legacy_session')) {
			console.debug('Already converted legacy session to auth token');
			return null;
		}

		const phpSessId = document.cookie
			.split(';')
			.map((part) => part.trim().split('='))
			.filter((part) => part[0] == 'PHPSESSID')?.[0]?.[1];

		if (!phpSessId || !phpSessId.length) {
			console.debug('No PHPSESSID present, skipping');
			return null;
		}

		const res = await this.fetch(
			`${this.args.url}/guest.auth.token.fromSession`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: superjson.stringify({ phpSessId }),
			},
			{ withoutAuth: true },
		);

		const rawData = await res.json();
		const data = superjson.deserialize(rawData.error || rawData?.result?.data) as any;

		if (rawData.error) {
			console.warn('Got error from session conversion API call', rawData);
			return null; // Not worth handling error, simply let them login again.
		}

		const accessGrant = AccessGrant.parse(data);
		await this.storeAccessGrant(accessGrant);
		window.localStorage.setItem('has_converted_legacy_session', '1');
		return accessGrant;
	}

	async storeAccessGrant(accessGrant: AccessGrant): Promise<void> {
		const value = JSON.stringify(accessGrant);
		await this.args.authStorage.setData(value);
	}

	public async clearAccessGrant(): Promise<void> {
		await this.args.authStorage.clearData();
	}

	public async getAuthAccount(): Promise<Account | null> {
		try {
			await this.startTokenRefresh();
		} catch (err) {
			if (err instanceof UnauthenticatedError) {
				return null;
			}
			throw err;
		}

		const grant = await this.getAccessGrant();

		if (!grant) {
			return null;
		}

		const { data } = this.decodeJWT(grant.accessToken);
		return data;
	}

	protected async acknowledgeTokenRefresh(accessGrant: AccessGrant): Promise<void> {
		await this.fetch(
			`${this.args.url}/guest.auth.token.acknowledge`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: superjson.stringify({
					refreshToken: accessGrant.refreshToken,
				}),
			},
			{ withoutAuth: true },
		);
	}

	protected async refreshAccessToken(currentAccessGrant: AccessGrant): Promise<void> {
		const res = await this.fetch(
			`${this.args.url}/guest.auth.token.renew`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: superjson.stringify({
					refreshToken: currentAccessGrant.refreshToken,
				}),
			},
			{ withoutAuth: true },
		);

		const rawData = await res.json();
		const data = superjson.deserialize(rawData.error || rawData?.result?.data) as any;

		if (rawData.error) {
			const error = data;

			if (error?.data?.name === Errors.RefreshTokenNotFoundError.name) {
				await this.clearAccessGrant();
				this.emitUnrecoverableAuthError();
				throw new UnauthenticatedError('Refresh token could not be found');
			} else {
				rethrowRawServerError(error);
			}
		}

		const accessGrant = AccessGrant.parse(data);

		await this.storeAccessGrant(accessGrant);
		await this.acknowledgeTokenRefresh(accessGrant);
	}

	protected isJWTExpired(jwt: string): boolean {
		const data = this.decodeJWT(jwt);
		const now = Math.floor(Date.now() / 1000);
		return now > data.exp - TOKEN_REFRESH_LEEWAY_SEC;
	}

	protected decodeJWT(jwt: string): JWTData {
		const [, body] = jwt.split('.');

		return JWTData.parse(JSON.parse(base64.decode(String(body))));
	}
}
